import axios, { AxiosInstance } from 'axios';
import { UserType } from "@/app/http/types";
import LocalStorage from "@/app/localStorage";
import {LS_KEY_USER} from "@/app/const";
import axiosInstance from "@/app/http/axios";
import {useRoute} from "vue-router";
import {ref} from "vue";

const userLocalStorage  = new LocalStorage(LS_KEY_USER);

let userInfo = userLocalStorage.getItems();


export default class HttpService {
  user: UserType;
  constructor() {
    this.user = userLocalStorage.getItems();
  }
  getUser() {
    return userLocalStorage.getItems();
  }
  async get(path: string) {
    try {
      return await axiosInstance.get(`${path}`);
    } catch (error: any) {
      throw error.message;
    }
  }
  async post(path: string, payload: { [key: string]: any }) {
    try {
      return await axiosInstance.post(`${path}`, payload);
    } catch (error: any) {
      throw error.response;
    }
  }

}
