<template>
  <div class="layout-card">
    <label class="layout-box"
      ><span class="d-flex h-100 flex-column gap-1 justify-content-end">
        <span
          class="bg-light d-flex pa-1 gap-1 mb-1 align-items-center justify-space-between"
        >
          <span
            class="layout-inline-circle layout-inline-box me-1 rounded-lg"
          ></span>
          <div class="d-flex">
            <span class="px-2 layout-inline-box me-1"></span
            ><span class="px-2 layout-inline-box"></span>
          </div> </span
        ><span class="bg-light d-block pa-1"></span
        ><span class="bg-light d-block pa-1 mt-auto"></span></span
    ></label>
  </div>
</template>
