import { createApp, App } from "vue";
import { registerPlugins } from "@/plugins";
import i18n from "@/plugins/i18n";
import AppMain from "@/App.vue";

import "@/assets/scss/app.scss";

import "simplebar";
import "simplebar/dist/simplebar.css";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import "emoji-picker-element";

import { vMaska } from "maska";
import { registerLicense } from '@syncfusion/ej2-base';

import "maz-ui/css/main.css";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";

import VueApexCharts from "vue3-apexcharts";
import CountTo from "@/app/lib/CountTo.vue";
import ListMenu from "@/app/common/components/ListMenu.vue";
import ListMenuWithIcon from "@/app/common/components/ListMenuWithIcon.vue";
import Card from "@/app/common/components/Card.vue";
import Breadcrumb from "@/app/common/components/Breadcrumb.vue";
import TextField from "@/app/common/validationComponents/TextField.vue";
import TextArea from "@/app/common/validationComponents/TextArea.vue";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import Vue3Lottie from 'vue3-lottie';
import Popper from "vue3-popper";
import Particles from "@tsparticles/vue3";
import { loadSlim } from "@tsparticles/slim";

const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    plugins: [createPersistedState()],
});

import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import {createPinia} from "pinia";
import {useLayoutStore} from "@/store/app";
import Vuetify from "@/plugins/vuetify";

const pinia = createPinia();


registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxcdnRdR2dfUUZ/XkQ=');

const app: App = createApp(AppMain);

registerPlugins(app);

app.use(i18n);
app.use(VueApexCharts);
app.use(pinia);
app.use(Vue3Lottie, { name: 'LottieAnimation' });
app.use(Particles, {
    init: async engine => {
        await loadSlim(engine); // or you can load the slim version from "@tsparticles/slim" if don't need Shapes or Animations
    },
});
app.use(Vuetify);
app.use(Vue3Toastify, {
    autoClose: 3000,
} as ToastContainerOptions);

app.component("Popper", Popper);
app.component("CountTo", CountTo);
app.component("ListMenu", ListMenu);
app.component("Card", Card);
app.component("VueDatePicker", VueDatePicker);
app.component("ListMenuWithIcon", ListMenuWithIcon);
app.component("Breadcrumb", Breadcrumb);
app.component("TextField", TextField);
app.component("TextArea", TextArea);
app.directive("maska", vMaska);
app.component("MazPhoneNumberInput", MazPhoneNumberInput);

app.mount("#app");

const layoutStore = useLayoutStore();

layoutStore.changeSideBarImage('img-1');
