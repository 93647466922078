import { DefaultLayout, AuthLayout } from "@/layouts/index";
import ECommerceVue from "@/views/dashboard/ECommerce.vue";

const authPrefix = "/geoblue/auth";
const pagesPrefix = "/pages";

const accountRoutes = [
  {
    path: `/login`,
    name: "AccountSignIn",
    component: () => import("@/views/account/SignIn.vue"),
    meta: { title: "Logar", authRequired: false },
  },
  {
    path: `/signup`,
    name: "AccountSignUp",
    component: () => import("@/views/account/SignUp.vue"),
    meta: { title: "Sign Up", authRequired: false }
  },
  {
    path: `/pass-reset`,
    name: "AccountResetPassword",
    component: () => import("@/views/account/ResetPassword.vue"),
    meta: { title: "Resetar Senha", authRequired: false }
  },
  {
    path: `/pass-change`,
    name: "AccountChangePassword",
    component: () => import("@/views/account/CreatePassword.vue"),
    meta: { title: "Criar nova senha", authRequired: false }
  }
].map((data) => {
  return {
    ...data,
    meta: { ...data.meta, layout: AuthLayout }
  };
});

const dashboardRoutes = [
  {
    path: "/",
    name: "ECommerce",
    component: ECommerceVue,
    meta: { title: "Dashboard", authRequired: true, layout: DefaultLayout }
  }
].map((data) => {
  return {
    ...data,
    meta: { ...data.meta, layout: DefaultLayout }
  };
});

const authRoutes = [
  {
    path: `${authPrefix}/signin`,
    name: "SignIn",
    component: () => import("@/views/authentication/SignIn.vue"),
    meta: { title: "Sign In", authRequired: false }
  },
  {
    path: `${authPrefix}/signup`,
    name: "SignUp",
    component: () => import("@/views/authentication/SignUp.vue"),
    meta: { title: "Sign Up", authRequired: false }
  },
  {
    path: `${authPrefix}/pass-reset`,
    name: "ResetPassword",
    component: () => import("@/views/authentication/ResetPassword.vue"),
    meta: { title: "Reset Password", authRequired: false }
  },
  {
    path: `${authPrefix}/pass-change`,
    name: "ChangePassword",
    component: () => import("@/views/authentication/CreatePassword.vue"),
    meta: { title: "Create New Password", authRequired: false }
  },
  {
    path: `${authPrefix}/lockscreen`,
    name: "LockScreen",
    component: () => import("@/views/authentication/LockScreen.vue"),
    meta: { title: "Lock Screen", authRequired: false }
  },
  {
    path: `${authPrefix}/logout`,
    name: "LogOut",
    component: () => import("@/views/authentication/Logout.vue"),
    meta: { title: "Log Out", authRequired: false }
  },
  {
    path: `${authPrefix}/success-msg`,
    name: "SuccessMessage",
    component: () => import("@/views/authentication/SuccessMsg.vue"),
    meta: { title: "Success Message", authRequired: false }
  },
  {
    path: `${authPrefix}/twostep`,
    name: "TwoStepVerification",
    component: () => import("@/views/authentication/TwoStepVerification.vue"),
    meta: { title: "Two Step Verification", authRequired: false }
  },
  {
    path: `${authPrefix}/404`,
    name: "Error404",
    component: () => import("@/views/authentication/error/404.vue"),
    meta: { title: "404 Error", authRequired: false }
  },
  {
    path: `${authPrefix}/500`,
    name: "Error500",
    component: () => import("@/views/authentication/error/500.vue"),
    meta: { title: "500 Error", authRequired: false }
  },
  {
    path: `${authPrefix}/503`,
    name: "Error503",
    component: () => import("@/views/authentication/error/503.vue"),
    meta: { title: "503 Error", authRequired: false }
  },
  {
    path: `${authPrefix}/offline`,
    name: "Offline",
    component: () => import("@/views/authentication/error/Offline.vue"),
    meta: { title: "Offline Page", authRequired: false }
  }
].map((data) => {
  return {
    ...data,
    meta: { ...data.meta, layout: AuthLayout }
  };
});

const errorRoutes  = [
  {
    path: `/423`,
    name: "Locked",
    component: () => import("@/views/pages/error/423.vue"),
    meta: { title: "Não Autorizado", authRequired: true, layout: DefaultLayout }
  },
  {
    path: `/404`,
    name: "NotFound",
    component: () => import("@/views/pages/error/404.vue"),
    meta: { title: "Não Encontrado", authRequired: true, layout: DefaultLayout }
  },
];


const pagesRoutes = [
  {
    path: `${pagesPrefix}/starter`,
    name: "Starter",
    component: () => import("@/views/pages/StarterKit.vue"),
    meta: { title: "Starter", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `${pagesPrefix}/maintenance`,
    name: "PagesMaintenance",
    component: () => import("@/views/pages/Maintenance.vue"),
    meta: { title: "Maintenance", authRequired: false, layout: AuthLayout }
  },
  {
    path: `${pagesPrefix}/coming-soon`,
    name: "PagesComingSoon",
    component: () => import("@/views/pages/ComingSoon.vue"),
    meta: { title: "Coming Soon", authRequired: false, layout: AuthLayout }
  }
];

const carbonPass = [
  {
    path: `/geoblue/carbonpass/parametros`,
    name: "carbonParams",
    component: () => import("@/views/pages/carbonpass/params/index.vue"),
    meta: { title: "Parâmetros", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas`,
    name: "carbonLists",
    component: () => import("@/views/pages/carbonpass/lists/index.vue"),
    meta: { title: "Listas", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/planos`,
    name: "carbonPlans",
    component: () => import("@/views/pages/carbonpass/plans/index.vue"),
    meta: { title: "Planos", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/permissoes`,
    name: "carbonPermissions",
    component: () => import("@/views/pages/carbonpass/permissions/index.vue"),
    meta: { title: "Permissões", authRequired: false, layout: DefaultLayout }
  },
];
const idrow = [
  {
    path: `/geoblue/idrow/planos`,
    name: "idrowPlans",
    component: () => import("@/views/pages/idrow/plans/index.vue"),
    meta: { title: "Planos", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/idrow/permissoes`,
    name: "idrowPermissions",
    component: () => import("@/views/pages/idrow/permissions/index.vue"),
    meta: { title: "Permissões", authRequired: false, layout: DefaultLayout }
  },
];

/*const adminParameterRoutes = [
  {
    path: `/admin/parametros/precursores`,

    name: "AdminPrecursors",
    component: () => import("@/views/pages/admin/parameters/precursors/index.vue"),
    meta: { title: "Precursores", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/admin/parametros/escopos`,
    name: "AdminScopes",
    component: () => import("@/views/pages/admin/parameters/scopes/index.vue"),
    meta: { title: "Escopos", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/admin/parametros/categorias`,
    name: "AdminCategories",
    component: () => import("@/views/pages/admin/parameters/categories/index.vue"),
    meta: { title: "Escopos", authRequired: false, layout: DefaultLayout }
  },
];*/

const managementRoutes = [
  {
    path: `/gerenciamento/parametros`,
    name: "Parameters",
    component: () => import("@/views/pages/management/params/index.vue"),
    meta: { title: "Parâmetros", authRequired: false, layout: DefaultLayout }
  },
];

const adminListRoutes = [
  {
    path: `/geoblue/carbonpass/listas/setores`,
    name: "AdminSectors",
    component: () => import("@/views/pages/admin/lists/sectors/index.vue"),
    meta: { title: "Setores", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas/combustiveis`,
    name: "AdminFuels",
    component: () => import("@/views/pages/admin/lists/fuels/index.vue"),
    meta: { title: "Combustíveis", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas/categorias/`,
    name: "AdminListCategories",
    component: () => import("@/views/pages/admin/lists/fuels/categories.vue"),
    meta: { title: "Categorias", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas/unidadesmedida`,
    name: "AdminUnityMeasure",
    component: () => import("@/views/pages/admin/lists/unitymeasure/index.vue"),
    meta: { title: "Unidades de Medida", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas/veiculos`,
    name: "AdminVehicles",
    component: () => import("@/views/pages/admin/lists/vehicles/index.vue"),
    meta: { title: "Veículos", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas/energias`,
    name: "AdminEnergies",
    component: () => import("@/views/pages/admin/lists/energies/index.vue"),
    meta: { title: "Energias", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas/cidades`,
    name: "AdminCities",
    component: () => import("@/views/pages/admin/lists/cities/index.vue"),
    meta: { title: "Cidades", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas/incineradores`,
    name: "AdminIncinarators",
    component: () => import("@/views/pages/admin/lists/incinerators/index.vue"),
    meta: { title: "Incineradores", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas/gasesefeitoestufa`,
    name: "AdminGases",
    component: () => import("@/views/pages/admin/lists/gases/index.vue"),
    meta: { title: "Gases de Efeito Estufa", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas/navios`,
    name: "AdminShips",
    component: () => import("@/views/pages/admin/lists/ships/index.vue"),
    meta: { title: "Navios", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas/caminhoes`,
    name: "AdminTrucks",
    component: () => import("@/views/pages/admin/lists/trucks/index.vue"),
    meta: { title: "Caminhões", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas/metros`,
    name: "AdminSubways",
    component: () => import("@/views/pages/admin/lists/metros/index.vue"),
    meta: { title: "Metrõs", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/geoblue/carbonpass/listas/concessionarias`,
    name: "AdminDeaslerships",
    component: () => import("@/views/pages/admin/lists/dealerships/index.vue"),
    meta: { title: "Concessionárias", authRequired: false, layout: DefaultLayout }
  },
];

export const routes = [
  ...accountRoutes,
  ...dashboardRoutes,
  ...authRoutes,
  ...errorRoutes,
  ...pagesRoutes,
  ...carbonPass,
  ...idrow,
  ...managementRoutes,
  ...adminListRoutes,
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/views/account/Logout.vue"),
    meta: { title: "Logout", authRequired: false }
  }
];
