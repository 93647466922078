import { FlagByLanguageType } from "@/app/locales/types";

import {
  usFlag,
  spainFlag,
  germanyFlag,
  italyFlag,
  russiaFlag,
  chinaFlag,
  frenchFlag,
  aeFlag, br,
} from "@/assets/images/flags/utils";

export const flagByLanguage: FlagByLanguageType[] = [
  {
    flag: br,
    language: "pt",
    title: "Português",
  },
  {
    flag: usFlag,
    language: "en",
    title: "English",
  },
];
