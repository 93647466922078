import {defineStore} from 'pinia';

import appConfigs from "@/app/appConfigurations";
import {httpService} from "@/app/http/apiServiceProvider";
import LocalStorage from "@/app/localStorage";
import {LS_KEY_USER} from "@/app/const";
import router from "@/router";
import {toast} from "vuetify-sonner";

const baseUrl = appConfigs.baseUrl;
const userLocalStorage = new LocalStorage(LS_KEY_USER);

export const useErrorStore = defineStore({
    id: 'error',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        title: '',
        message: ''
    }),
    // could also be defined as
    // state: () => ({ count: 0 })
    actions: {
        setErrorMessage(title: string, msg: string) {
          this.title = title;
          this.message = msg;
        },
        clearErrorMessage() {
          this.message = '';
        },
    },
});