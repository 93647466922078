import {defineStore} from 'pinia';

import appConfigs from "@/app/appConfigurations";
import {httpService} from "@/app/http/apiServiceProvider";
import LocalStorage from "@/app/localStorage";
import {LS_KEY_USER} from "@/app/const";
import router from "@/router";
import {toast} from "vuetify-sonner";

const baseUrl = appConfigs.baseUrl;
const userLocalStorage = new LocalStorage(LS_KEY_USER);

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: userLocalStorage.getItems().user,
        token: userLocalStorage.getItems().token,
    }),
    // could also be defined as
    // state: () => ({ count: 0 })
    actions: {
        async login(email: string, password: string) {
            try {
                const response = await httpService.post(`${baseUrl}/geoblue/auth/login`, {email, password});

                console.log(response.data);
                this.user     = response.data.user;
                this.token    = response.data.access_token;

                // store user details and jwt in local storage to keep user logged in between page refreshes
                userLocalStorage.setItems({
                    token: response.data.access_token,
                    user: response.data.user,
                });

                toast.success('Login realizado com sucesso!');

                await router.push('/');
            }catch (error) {
                throw error;
            }

        },
        async logout() {
            try {
                let response = await httpService.post(`/geoblue/auth/logout`, {

                });

                if(response){
                    toast.success("Você foi deslogado com sucesso");
                    userLocalStorage.removeItem();
                }

            } catch (error: any) {
                toast.error('Erro ao deslogar');
            }finally {
                this.user = null;
                this.token = null;
                userLocalStorage.removeItem();
                localStorage.clear();
                await router.push('/login');
            }
        },
        async checkToken() {
            try {
                let response = await httpService.post(`/geoblue/auth/refresh`,{});

                if(response){
                    userLocalStorage.setItems({
                        token: response.data.authorization.token,
                    });
                }
            } catch (error: any) {
                toast.error(error.message);
                throw error.message;
            }
        }
    },
});
