import axios from "axios";
import type { AxiosInstance } from "axios";
import appConfigs from "@/app/appConfigurations";
import LocalStorage from "@/app/localStorage";
import {LS_KEY_USER} from "@/app/const";
import {httpService} from "@/app/http/apiServiceProvider";
import {toast} from "vuetify-sonner";
import {useAuthStore} from "@/stores/auth.store";
import router from "@/router";
import {useErrorStore} from "@/stores/error.store";

const userLocalStorage = new LocalStorage(LS_KEY_USER);

const userAuth = userLocalStorage.getItems();

const getBaseUrl = (): string => {
  return appConfigs.baseUrl;
};

const axiosInstance: AxiosInstance = axios.create({
  baseURL: getBaseUrl(),
});

axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axiosInstance.interceptors.request.use(config => {
  const authStore = useAuthStore();

  if(authStore.token){
    config.headers.Authorization = `Bearer ${authStore.token}`
  }
  return config; // Don't forget to return the modified config
});

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {

  const userAuth = useAuthStore();
  const errorStore = useErrorStore();

  if (error.response && error.response.status === 401) {
    userLocalStorage.removeItem();
    toast.error(error.response.data.message);
    router.push('/login');
  }
  if (error.response && error.response.status === 404) {
    errorStore.setErrorMessage('Rota não encontrada', 'A rota que você está tentando acessar não foi encontrada :(');
    router.push('/404');
  }
  if (error.response && error.response.status === 423) {
    errorStore.setErrorMessage('Não Autorizado', error.response.data ?? error.response.data.message);
    router.push('/423')
  }
  if (error.response && error.response.status === 500) {
    toast.error('Você não tem permissão para realizar essa ação ou acessar este recurso');
    return;
  }
  return Promise.reject(error);
});

export default axiosInstance;
