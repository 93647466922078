<template>
  <div class="layout-card">
    <label class="layout-box pa-0"
      ><span class="d-flex gap-1 h-100"
        ><span
          ><span class="bg-light d-flex h-100 flex-column gap-1 me-1"
            ><span class="d-block pa-1 layout-inline-box mb-2"></span
            ><span class="d-block pt-1 layout-inline-box mb-1"></span
            ><span class="d-block pt-1 layout-inline-box mb-1"></span
            ><span
              class="d-block pa-1 pb-0 layout-inline-box"
            ></span></span></span
        ><span
          ><span class="bg-light d-flex h-100 flex-column gap-1 pa-1 me-1"
            ><span class="d-block pa-1 px-2 pb-0 mb-1 layout-inline-box"></span
            ><span class="d-block pa-1 px-2 pb-0 mb-1 layout-inline-box"></span
            ><span class="d-block pa-1 px-2 pb-0 mb-1 layout-inline-box"></span
            ><span
              class="d-block pa-1 px-2 pb-0 layout-inline-box"
            ></span></span></span
        ><span class="flex-grow-1"
          ><span class="d-flex h-100 flex-column"
            ><span class="bg-light d-block pa-1"></span
            ><span
              class="bg-light d-block pa-1 mt-auto"
            ></span></span></span></span
    ></label>
  </div>
</template>
