<script lang="ts" setup>
import { brandsList } from "@/components/layouts/utils";
import {useAuthStore} from "@/stores/auth.store";

const auth = useAuthStore();

const logout = () => {
  auth.logout();
}
</script>
<template>
  <v-menu width="185">
    <template v-slot:activator="{ props }">
      <a dark v-bind="props" class="d-flex align-center mx-3">
        <v-avatar size="small" class="user-profile">
          <v-img
            class="header-profile-user"
            src="https://media.licdn.com/dms/image/D4D03AQFH4KmDpDGhXg/profile-displayphoto-shrink_100_100/0/1719956982492?e=1727913600&v=beta&t=9scXQ1ztB09fwtAFYCxCFnOwuRGZ-fJJsnM_rbqdlNY"
            alt="Header Avatar"
          />
        </v-avatar>
        <span class="text-start ms-xl-3">
          <h4
            class="d-none d-xl-inline-block user-name-text font-weight-medium"
          >
            {{ auth?.user?.name }}
          </h4>
          <span class="d-none d-xl-block user-name-sub-text">{{ 'Teste' }}</span>
        </span>
      </a>
    </template>
    <v-list density="compact" :lines="false" class="profile-list" nav>
      <h6 class="dropdown-header">Bem vindo Gustavo!</h6>
      <v-list-item class="dropdown-item" @click="">
        <i class="mdi mdi-account-circle text-muted" />
        Perfil
      </v-list-item>
      <v-list-item class="dropdown-item" @click="">
        <i class="mdi mdi-message-text-outline text-muted" />
        Mensagens
      </v-list-item>
<!--      <v-list-item class="dropdown-item" @click="">-->
<!--        <i class="mdi mdi-calendar-check-outline text-muted" />Task board-->
<!--      </v-list-item>-->
<!--      <v-list-item class="dropdown-item" @click="">-->
<!--        <i class="mdi mdi-lifebuoy text-muted" />Help-->
<!--      </v-list-item>-->
      <v-divider class="my-2" />
<!--      <v-list-item class="dropdown-item" @click="" to="/pages/profile">-->
<!--        <div class="d-flex justify-space-between">-->
<!--          <div>-->
<!--            <i class="mdi mdi-wallet text-muted" />-->
<!--            Balance-->
<!--          </div>-->
<!--          <span><b>$8451.36</b></span>-->
<!--        </div>-->
<!--      </v-list-item>-->
      <v-list-item class="dropdown-item" @click="" to="/">
        <i class="mdi mdi-cog-outline text-muted" />
        <span class="align-middle">Configurações</span>
      </v-list-item>
      <v-list-item class="dropdown-item" @click="" to="/">
        <i class="mdi mdi-lock text-muted" />
        <span class="align-middle">Bloquear Tela</span>
      </v-list-item>
      <v-list-item class="dropdown-item" @click="logout">
        <i class="mdi mdi-logout text-muted" />
        <span class="align-middle" data-key="t-logout">Deslogar </span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
